
import { defineComponent } from 'vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default defineComponent({
  name: 'PasswordRecoverySection',
  data () {
    const schema = yup.object().shape({
      password: yup.string().required('Введите пароль'),
      passwordRecovery: yup.string().required('Введите пароль повторно')
    })

    return {
      isModalOpen: false,
      schema,
      translateData: {},
      dataPopup: 'Ваш пароль восстановлен!'
    }
  },
  components: {
    DefaultPopup,
    Form,
    Field,
    ErrorMessage
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('tech_support', 'save_button')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    sendEmail (data) {
      data.token = this.$route.query.token
      this.$store.dispatch('auth/newPassword', data)
      this.isModalOpen = true
    },
    handleCloseModal () {
      this.$router.push('/')
    }
  }
})
