
import { defineComponent } from 'vue'
import SecondLayout from '@/templates/SecondLayout.vue'
import PasswordRecoverySection from '@/components/sections/PasswordRecoverySection.vue'

export default defineComponent({
  name: 'PasswordRecovery',
  components: {
    SecondLayout,
    PasswordRecoverySection
  }
})
