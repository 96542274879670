import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f8adaab"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "password-recovery-section"
}
const _hoisted_2 = { class: "password-recovery-section__container" }
const _hoisted_3 = { class: "password-recovery-section__title" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "password-recovery-section__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(this.translateData.forgot_password_header), 1),
          _createVNode(_component_Form, {
            onSubmit: _ctx.sendEmail,
            "validation-schema": _ctx.schema
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Field, {
                  name: "password",
                  type: "password",
                  placeholder: "Password",
                  class: "form-control"
                }),
                _createVNode(_component_ErrorMessage, {
                  name: "password",
                  class: "error-feedback"
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Field, {
                  name: "passwordRecovery",
                  type: "password",
                  placeholder: "Password recovery",
                  class: "form-control"
                }),
                _createVNode(_component_ErrorMessage, {
                  name: "passwordRecovery",
                  class: "error-feedback"
                })
              ]),
              _createElementVNode("button", _hoisted_6, _toDisplayString(this.translateData.save_button), 1)
            ]),
            _: 1
          }, 8, ["onSubmit", "validation-schema"])
        ]),
        (_ctx.isModalOpen)
          ? (_openBlock(), _createBlock(_component_DefaultPopup, {
              key: 0,
              message: this.dataPopup,
              "on-close": _ctx.handleCloseModal
            }, null, 8, ["message", "on-close"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}